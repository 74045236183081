import { createSlice } from '@reduxjs/toolkit';
import { groupRoomsAdapter } from 'api/helpers/roomAdapter';
import { IDeleteRoomInput } from 'api/types/room';
import { AxiosError } from 'axios';
import { SEPARATOR } from 'constants/livekit';
import { errorMessages, successMessages } from 'constants/messages';
import { reducersNames } from 'constants/reducers';
import {
  IGroupRooms,
  IRoomsSlice,
  ITreeRooms,
  TSliceWithPromiseFlags,
} from 'interfaces';
import { IClan } from 'interfaces/clan';

import { notify, updateTreeRoom } from 'utils';

import {
  createRoomIngressThunk,
  createRoomThunk,
  deleteRoomThunk,
  getAllLiveKitTokensThunk,
  getAllRoomsThunk,
  getClanRoomsThunk,
  getLiveKitTokenThunk,
  getRecordingSignalThunk,
} from './actions';

const initialState: TSliceWithPromiseFlags<IRoomsSlice> & { loader: boolean } =
  {
    rooms: new Map<string, IGroupRooms>(),
    treeRooms: [],
    selectedRoom: null,
    activeRoomsByClanId: [],
    error: '',
    pending: false,
    loader: false,
    url: '',
    streamKey: '',
  };

const roomsSlice = createSlice({
  name: reducersNames.STREAM,
  initialState,
  reducers: {
    setTreeRooms(state, { payload }) {
      const newTreeRooms = payload.reduce(
        (treeRooms: ITreeRooms[], clan: IClan) => {
          const activeRooms = state.rooms.get(clan.id)?.active;

          const foundData = state.treeRooms.find(
            (room) => room.clanId === clan.id
          );

          if (foundData) {
            if (activeRooms?.length) {
              const rooms = updateTreeRoom(foundData.rooms, activeRooms);

              const isHiddenRooms = rooms.filter((room) => room.isHidden);

              treeRooms.push({
                clanName: clan.name,
                clanId: String(clan.id),
                rooms,
                isHidden: !!isHiddenRooms.length,
              });
            }
          } else {
            if (activeRooms?.length) {
              treeRooms.push({
                clanName: clan.name,
                clanId: String(clan.id),
                rooms: activeRooms,
                isHidden: false,
              });
            }
          }

          return treeRooms;
        },
        []
      );

      state.treeRooms = newTreeRooms;
    },
    allRoomsHideController(state, { payload }) {
      const updatedTreeRooms = state.treeRooms.map((data) => ({
        ...data,
        rooms: data.rooms.map((room) => ({ ...room, isHidden: payload })),
        isHidden: payload,
      }));

      state.treeRooms = updatedTreeRooms;
    },
    roomHideController(state, { payload: { clanId, livekitName, isHidden } }) {
      const rooms = state.treeRooms.find(
        (rooms) => rooms.clanId === clanId
      )?.rooms;

      const updatedRooms = rooms?.map((room) =>
        room.livekitName === livekitName
          ? {
              ...room,
              isHidden:
                typeof isHidden === 'boolean' ? isHidden : !room.isHidden,
            }
          : room
      );

      if (updatedRooms?.length) {
        const newTreeRooms = state.treeRooms.reduce(
          (acc: ITreeRooms[], data) => {
            if (data.clanId === clanId) {
              const hiddenRooms = updatedRooms.filter((room) => room.isHidden);

              acc = [
                ...acc,
                {
                  ...data,
                  rooms: updatedRooms,
                  isHidden:
                    (typeof isHidden === 'boolean' && isHidden) ||
                    !!hiddenRooms.length,
                },
              ];
            } else {
              acc = [...acc, data];
            }

            return acc;
          },
          []
        );

        state.treeRooms = newTreeRooms;
      }
    },
    resetHiddenRooms(state) {
      state.rooms.forEach((room) => {
        room.active.forEach((activeRoom) => {
          activeRoom.isHidden = false;
        });
      });
    },
    selectActiveRoomsByClanId(state, { payload: { clanId } }) {
      const rooms = state.rooms.get(clanId)?.active;

      state.activeRoomsByClanId = rooms || [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        getClanRoomsThunk.fulfilled,
        (state, { payload: { clanId, rooms } }) => {
          if (rooms.length) {
            const groupRooms = groupRoomsAdapter(rooms, clanId, state.rooms);

            state.rooms.set(clanId, groupRooms);
          } else {
            state.rooms.delete(clanId);
          }

          state.pending = false;
        }
      )
      .addCase(getClanRoomsThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getClanRoomsThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(getAllRoomsThunk.fulfilled, (state, { payload }) => {
        payload.forEach(({ rooms, clanId }) => {
          if (rooms.length) {
            const groupRooms = groupRoomsAdapter(rooms, clanId, state.rooms);

            state.rooms.set(clanId, groupRooms);
          } else {
            state.rooms.delete(clanId);
          }
        });

        if (state.rooms.size > payload.length) {
          const filteredKeys = Array.from(state.rooms.keys()).filter(
            (key) => !payload.some((value) => value.clanId === key)
          );

          filteredKeys.forEach((key) => {
            state.rooms.delete(key);
          });
        }

        state.pending = false;
      })
      .addCase(getAllRoomsThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getAllRoomsThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(
        createRoomThunk.fulfilled,
        (
          state,
          {
            payload: {
              livekitName,
              roomName,
              numParticipants,
              accountId,
              token,
              src,
              isActive,
              createdAt,
            },
          }
        ) => {
          state.selectedRoom = {
            livekitName,
            roomName,
            numParticipants,
            accountId,
            clanId: livekitName.split(SEPARATOR)[0],
            src,
            isRecording: false,
            isActive,
            token,
            createdAt,
          };

          state.pending = false;
          state.error = null;
        }
      )
      .addCase(createRoomThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(createRoomThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.CREATE_ROOM;
      })
      .addCase(createRoomIngressThunk.fulfilled, (state, { payload }) => {
        if (payload?.stream_key && payload?.url) {
          state.url = payload.url;
          state.streamKey = payload.stream_key;

          state.loader = false;
          state.error = null;
        }
      })
      .addCase(createRoomIngressThunk.pending, (state) => {
        state.loader = true;
        state.error = null;
      })
      .addCase(createRoomIngressThunk.rejected, (state) => {
        state.loader = false;
        state.error = errorMessages.CREATE_ROOM;
      })
      .addCase(
        getAllLiveKitTokensThunk.fulfilled,
        (state, { payload: { items } }) => {
          items.forEach(({ clan_id, room_name, token }) => {
            const rooms = state.rooms.get(clan_id);

            if (rooms) {
              const activeRoom = rooms.active.find(
                (room) => room.roomName === room_name
              );

              if (activeRoom) {
                activeRoom.token = token;
              }

              state.rooms.set(clan_id, rooms);
            }
          });

          if (state.activeRoomsByClanId) {
            state.activeRoomsByClanId = Array.from(
              state.rooms.values()
            ).flatMap((room) => room.active);
          }
        }
      )
      .addCase(getAllLiveKitTokensThunk.rejected, (state, { payload }) => {
        const error = payload as AxiosError<{ code: string; message: string }>;

        if (error.response) {
          const { data } = error.response;
          state.error = data.message;
        }
      })
      .addCase(
        getLiveKitTokenThunk.fulfilled,
        (state, { payload: { token, clanId, roomName } }) => {
          const rooms = state.rooms.get(clanId);

          if (rooms) {
            const room = rooms.active.find(
              (item) => item.roomName === roomName
            );

            if (room) {
              room.token = token;
            }

            if (state.activeRoomsByClanId) {
              state.activeRoomsByClanId = rooms.active;
            }

            state.rooms.set(clanId, rooms);
          }
        }
      )
      .addCase(getLiveKitTokenThunk.rejected, (state, { payload }) => {
        const error = payload as AxiosError<{ code: string; message: string }>;

        if (error.response) {
          const { data } = error.response;
          state.error = data.message;
        }
      })
      .addCase(deleteRoomThunk.fulfilled, (state, { payload }) => {
        const { clanId, roomName } = payload as unknown as IDeleteRoomInput;

        if (clanId) {
          const clanRooms = state.rooms.get(clanId);

          if (clanRooms) {
            const { active } = clanRooms;

            state.rooms.set(clanId, {
              ...clanRooms,
              active: active.filter((room) => room.roomName !== roomName),
            });
          }
        }

        state.selectedRoom = null;
      })
      .addCase(
        getRecordingSignalThunk.fulfilled,
        (state, { payload: { isRecording, ...roomInfo } }) => {
          state.selectedRoom = { ...roomInfo, isRecording };

          if (isRecording) {
            notify.success(successMessages.START_RECORDING);
          } else {
            notify.warning(errorMessages.START_RECORDING);
          }
        }
      );
  },
});

export const { actions: roomActions, reducer: roomReducer } = roomsSlice;

export * from './selectors';
